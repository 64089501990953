@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
html {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

*, *:before, *:after {
  box-sizing: inherit;
}

hmtl, body {
  margin: 0;
  padding: 0;
}

@media (min-width: 1030px) {
  #active-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    bottom: -2px;
    background-color: #fff;
    visibility: visible;
    transform: scale(1);
  }
}
